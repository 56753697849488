/* --------------------------------

LineIcons Web Font
Generated using nucleoapp.com

-------------------------------- */
@import "variables";
@import "mixins";

@font-face {
  font-family: 'LineIcons';
  src: url('#{$lni-font-path}/LineIcons.eot');
  src: url('#{$lni-font-path}/LineIcons.eot') format('embedded-opentype'),
    url('#{$lni-font-path}/LineIcons.woff2') format('woff2'),
    url('#{$lni-font-path}/LineIcons.woff') format('woff'),
    url('#{$lni-font-path}/LineIcons.ttf') format('truetype'),
    url('#{$lni-font-path}/LineIcons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.lni {
  display: inline-block;
  font: normal normal normal 1em/1 'LineIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */
.lni-sm {
  font-size: 0.8em;
}
.lni-lg {
  font-size: 1.2em;
}
/* absolute units */
.lni-16 {
  font-size: 16px;
}
.lni-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.lni-bg-square,
.lni-bg-circle {
  padding: 0.35em;
  background-color: $lni-background-color;
}

.lni-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.lni-ul {
  padding-left: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  > li > .lni {
    margin-right: $lni-li-margin-right;
    line-height: inherit;
  }
}

/*------------------------
  spinning icons
-------------------------*/

.lni-is-spinning {
  -webkit-animation: lni-spin 2s infinite linear;
  -moz-animation: lni-spin 2s infinite linear;
  animation: lni-spin 2s infinite linear;
}

@-webkit-keyframes lni-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes lni-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes lni-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.lni-rotate-90  {
	@include lni-rotate(90deg, 1);
}

.lni-rotate-180 {
	@include lni-rotate(180deg, 2);
}

.lni-rotate-270 {
	@include lni-rotate(270deg, 3);
}

.lni-flip-y {
	@include lni-flip(-1, 1, 0);
}
.lni-flip-x {
	@include lni-flip(1, -1, 2);
}

@import "icons";
